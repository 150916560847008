import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {IReporting} from '@ic3/reporting-api-embedded';
import {DashboardsDivReactContext} from "./DashboardsDivContext";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";

interface IEmbeddedDiv {
    uid: string;
    className?: string;
    autoResize?: boolean;
    onIc3ready: (ic3: IReporting) => void;
}


export function EmbeddedDiv(props: IEmbeddedDiv) {

    const {uid, className, autoResize, onIc3ready} = props;

    const context = useContext(DashboardsDivReactContext);
    const [loadingReporting, setLoadingReporting] = useState(true);
    const refContainer = useRef<HTMLDivElement>(null);
    const [error, setError] = useState<string>("");

    const ic3ready = useCallback((ic3: IReporting) => {
        onIc3ready(ic3);
        setLoadingReporting(false);
    }, [onIc3ready]);

    useEffect(() => {

        if (refContainer.current) {

            const options = {
                uid,
                container: refContainer.current,
                resizingContainer: autoResize ? refContainer.current : undefined
            }

            refContainer.current && context.loadLibsAndInitialize(options)
                .then(ic3ready)
                .catch(reason => {
                    setError(reason);
                    setLoadingReporting(false);
                });

        }

    }, [context, refContainer, setError, uid, autoResize, ic3ready]);

    return <>
        {loadingReporting &&
            <Box width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <CircularProgress/>
            </Box>}
        <Box className={className} ref={refContainer} height={'100%'}>{error}</Box>
    </>;
}
import * as React from 'react';
import {Box} from "@mui/material";
import {IReportDefinition} from "@ic3/reporting-api-embedded";

interface Props {
    reportDef: IReportDefinition | null;
}

export default function PageIccubeDashboardEditor(props: Props) {

    const url = new URL(window.location.origin + "/icCube/report/editor");
    url.searchParams.append('ic3customHeaders', 'edit');
    url.searchParams.append('ic3report', props.reportDef?.getPath() ?? "");

    return <Box width={'100%'} overflow={'hidden'} flex={1}>
        <iframe title="ic-admin" id="ic3-iframe" width={"100%"} height={"100%"} src={url.toString()}/>
    </Box>
}